import { DateTime } from 'luxon';

import { createCrmHeldenavond } from '../../../api/backend.js';
import api from "api/api";
import { Server } from "Utils/config";
import { getData } from "Utils/form";
import { cleanProperties } from "Utils/object";

const heldenAvondFormFields = [
	'date',
	'firstName', 
	'lastName', 
	'emailAddress', 
	'phoneNumber', 
	'comments',
	'birthdate',
    "status",
	'sendEmails'
]

export async function updateHeldenAvondAction(heldenAvondFormData, event) {
	let mainRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, [], ['date', 'status']) };
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	delete mainRequestToSave.$$type;
	await api.updateDocument(Server.databaseID, Server.collections['HeldenavondRequest'], event.data.mainRequest.$id, mainRequestToSave );
	let detailsRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, ['date', 'status']) };
	if(event.data.detailsRequest.$id) {
		console.log("detailsRequestToSave", detailsRequestToSave);
		await api.updateDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], event.data.detailsRequest.$id, detailsRequestToSave);
	} else {
		console.log("detailsRequestToSave (creating)", detailsRequestToSave);
		detailsRequestToSave.requestId = mainRequestToSave.$id;
		await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], detailsRequestToSave);
	}
	return true;
}

/**
 * AppwriteId = Sized (Equals 36) String
 * EmailAddress = Sized (Max 254) String
 * HeldenavondFormData = MergeRight (Request & RequestDetails) {
 *  birthdate : Date
 * }
 * Iso8601Date = String
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt      : Iso8601DateTime
 *  $id             : AppwriteId
 *  birthdate       : Iso8601DateTime
 *  comments        : String
 *  emailAddress    : EmailAddress
 *  firstName       : String
 *  lastName        : String
 *  phoneNumber     : PhoneNumber
 *  sendEmails      : Boolean
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * @param heldenAvondFormData HeldenavondFormData
 * @param selectedDay Null | Date | Iso8601Date | Iso8601DateTime
 * @return Promise True
 */
export async function addHeldenAvondAction(heldenAvondFormData, selectedDay) {
	let mainRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, [], ['date', 'status']) };
	mainRequestToSave.date = selectedDay;
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	let request = await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequest'], mainRequestToSave);
	let requestId = request.$id;
	let detailsRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, ['date', 'status']) };
	let createData = { ...detailsRequestToSave, requestId: requestId };
	const requestDetails = await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], createData);

	await createCrmHeldenavond({
		appwriteRequestDetailsId:   requestDetails.$id,
		appwriteRequestId:          requestId,
		birthdate:                  DateTime.fromISO(requestDetails.birthdate).toISODate(),
		creationTime:               request.$createdAt,
		emailAddress:               requestDetails.emailAddress,
		eventDate:                  DateTime.fromISO(request.date).toISODate(),
		firstName:                  requestDetails.firstName,
		lastName:                   requestDetails.lastName,
		phoneNumber:                requestDetails.phoneNumber,
		requesterComments:          requestDetails.requesterComments,
		sendEmails:                 requestDetails.sendEmails
	});

	return true;
}

export async function restoreHeldenAvondBackup(event, details) {
	let mainEvent = cleanProperties(event);
	mainEvent.$id = event.$id;
	let eventDetails = cleanProperties(details);
	eventDetails.$id = details.$id;
	let request = await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequest'], mainEvent);
	eventDetails.requestId = request.$id;
	await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], eventDetails);
	return 2;
}

/**
 * AppwriteId = Sized (Equals 36) String
 * EmailAddress = Sized (Max 254) String
 * HeldenavondFormData = MergeRight (Request & RequestDetails) {
 *  birthdate : Date
 * }
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt      : Iso8601DateTime
 *  $id             : AppwriteId
 *  birthdate       : Iso8601DateTime
 *  comments        : String
 *  emailAddress    : EmailAddress
 *  firstName       : String
 *  lastName        : String
 *  phoneNumber     : PhoneNumber
 *  sendEmails      : Boolean
 * }
 * Result = {
 *
 *  foundErrors : Boolean
 *
 *  errors : {
 *      birthdate       : Boolean
 *      emailAddress    : Boolean
 *      firstName       : Boolean
 *      lastName        : Boolean
 *      phoneNumber     : Boolean
 *  }
 *
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * @param heldenAvondFormData HeldenavondFormData
 * @return Result
 */
export function validateHeldenAvondForm(heldenAvondFormData) {
    let errors = {};

    errors['firstName'] = !heldenAvondFormData.firstName;
    errors['lastName'] = !heldenAvondFormData.lastName;
    errors['emailAddress'] = !(heldenAvondFormData.emailAddress && heldenAvondFormData.emailAddress.match(/[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$/));
    errors['phoneNumber'] = !heldenAvondFormData.phoneNumber;
    errors['birthdate'] = !heldenAvondFormData.birthdate;
    let foundErrors = Object.keys(errors).length > 0
    return { errors, foundErrors }
}

/**
 * AppwriteId = Sized (Equals 36) String
 * EmailAddress = Sized (Max 254) String
 * Event = {
 *  type : 'heldenavond'
 *  data : {
 *      detailsRequest  : RequestDetails
 *      mainRequest     : Request
 *  }
 * }
 * HeldenavondFormData = MergeRight (Request & RequestDetails) {
 *  birthdate : Date
 * }
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt      : Iso8601DateTime
 *  $id             : AppwriteId
 *  birthdate       : Iso8601DateTime
 *  comments        : String
 *  emailAddress    : EmailAddress
 *  firstName       : String
 *  lastName        : String
 *  phoneNumber     : PhoneNumber
 *  sendEmails      : Boolean
 * }
 * Result = HeldenavondRequestDetails | { sendEmails : False }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * @param event Event
 * @return Result
 */
export function createHeldenAvondFormData(event) {
    if(event && event.data && event.data.mainRequest) {
        let heldenAvondFormData = { 
            ...getData(event.data.mainRequest, heldenAvondFormFields), 
            ...getData(event.data.detailsRequest, heldenAvondFormFields),
            birthdate: event.data.detailsRequest?.birthdate ? new Date(event.data.detailsRequest?.birthdate) : null
        };
        return heldenAvondFormData;
    } 
    return { sendEmails: false };
}
