import { useState, useEffect } from "react";
import FormHeldenAvondPanel from "../../../Components/Forms/FormHeldenAvondPanel";
import { addHeldenAvondAction, updateHeldenAvondAction, validateHeldenAvondForm, createHeldenAvondFormData } from "../Actions/Heldenavond";

/**
 * Properties -> React.ReactNode
 * AppwriteId = Sized (Equals 36) String
 * EmailAddress = Sized (Max 254) String
 * Event = {
 *  type : 'heldenavond'
 *  data : {
 *      detailsRequest  : RequestDetails
 *      mainRequest     : Request
 *  }
 * }
 * Iso8601Date = String
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Properties = {
 *  event       : Event
 *  onClose     : (Undefined | ToastParameters) -> *
 *  reload      : (Undefined | ToastParameters) -> *
 *  selectedDay : Null | Iso8601Date
 * }
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt      : Iso8601DateTime
 *  $id             : AppwriteId
 *  birthdate       : Iso8601DateTime
 *  comments        : String
 *  emailAddress    : EmailAddress
 *  firstName       : String
 *  lastName        : String
 *  phoneNumber     : PhoneNumber
 *  sendEmails      : Boolean
 * }
 * Status = 'confirmed' | 'pending' | 'rejected'
 * ToastParameters : {
 *  duration    : Undefined | Null | Integer
 *  message     : Undefined | String
 *  show        : Undefined | Null | Boolean
 *  type        : Undefined | 'error' | 'success'
 * }
 * @param properties Properties
 * @return React.ReactNode
 */
export default function FormHeldenAvondContainer({ selectedDay, event, reload, onClose }) {

    const [heldenAvondFormData, setHeldenAvondFormData] = useState({ sendEmails: false  });
    const [heldenAvondErrors, setHeldenAvondErrors] = useState({});
	const [loading, setLoading] = useState(false);

    useEffect(() => {
		setHeldenAvondFormData(createHeldenAvondFormData(event));
    }, [event])

	const updateHeldenAvond = async (heldenAvondFormData) => {
		setLoading(true);
		try {
			await updateHeldenAvondAction(heldenAvondFormData, event);
			setLoading(false);
			onClose({ message: "Heldenavond is aangepast" });
			reload();
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	/**
	 * AppwriteId = Sized (Equals 36) String
	 * EmailAddress = Sized (Max 254) String
	 * HeldenavondFormData = MergeRight (Request & RequestDetails) {
	 *  birthdate : Date
	 * }
	 * Iso8601DateTime = String
	 * PhoneNumber = Sized (Max 31) String
	 * Request = {
	 *  $createdAt  : Iso8601DateTime
	 *  $id         : AppwriteId
	 *  date        : Iso8601DateTime
	 *  status      : Status
	 * }
	 * RequestDetails = {
	 *  $createdAt      : Iso8601DateTime
	 *  $id             : AppwriteId
	 *  birthdate       : Iso8601DateTime
	 *  comments        : String
	 *  emailAddress    : EmailAddress
	 *  firstName       : String
	 *  lastName        : String
	 *  phoneNumber     : PhoneNumber
	 *  sendEmails      : Boolean
	 * }
	 * Sized S T = T
	 * Status = 'confirmed' | 'pending' | 'rejected'
	 * @param heldenAvondFormData HeldenavondFormData
	 * @return Promise Void
	 */
    const addHeldenAvond = async (heldenAvondFormData) => {
		setLoading(true);
		try {
			await addHeldenAvondAction(heldenAvondFormData, selectedDay);
			setLoading(false);
			onClose({ message: 'Heldenavond is aangemaakt.' });
			reload();
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	const validate = (heldenAvondFormData) => {
		let { errors } = validateHeldenAvondForm(heldenAvondFormData);
		setHeldenAvondErrors(errors);
		return Object.values(errors).some((error) => error);
	}

	const saveHeldenAvond = async () => {

		if(!validate(heldenAvondFormData)) {

			if(event?.data?.mainRequest?.$id) {
				return updateHeldenAvond(heldenAvondFormData);
			} else {
				return addHeldenAvond(heldenAvondFormData);
			}
		}
	}


    return <FormHeldenAvondPanel 
		immutable={event?.data?.mainRequest?.$id != null}
		loading={loading}
		errors={heldenAvondErrors} 
		selectedDay={selectedDay} 
		onSave={saveHeldenAvond} 
		heldenAvond={heldenAvondFormData} 
		setHeldenAvond={setHeldenAvondFormData} />


}
