import { useState, useEffect } from "react";
import FormTeamUitjePanel from "../../../Components/Forms/FormTeamUitjePanel";
import { useBackupContext } from "Context/BackupContext"

import { addTeamuitjeAction, updateTeamuitjeAction, validateTeamuitjeForm, createTeamuitjeFormData } from "../Actions/Teamuitje";

/**
 * Properties -> React.ReactNode
 * AppwriteId = Sized (Equals 36) String
 * Daypart = 'afternoon' | 'morning'
 * EmailAddress = Sized (Max 254) String
 * Event = {
 *  type : 'teamuitje'
 *  data : {
 *      detailsRequest      : RequestDetails
 *      mainRequest         : Request
 *      unavailableDayParts : Array Daypart
 *  }
 * }
 * Iso8601Date = String
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Properties = {
 *  event       : Event
 *  onClose     : (Undefined | ToastParameters) -> *
 *  reload      : (Undefined | ToastParameters) -> *
 *  selectedDay : Null | Iso8601Date
 * }
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  daypart     : Daypart
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt              : Iso8601DateTime
 *  $id                     : AppwriteId
 *  amountOfParticipants    : Sized (InclusiveRange 12 25) Integer
 *  comments                : String
 *  department              : String
 *  emailAddress            : EmailAddress
 *  firstName               : String
 *  lastName                : String
 *  lunch                   : Boolean
 *  organizationAddress     : String
 *  organizationCity        : String
 *  organizationName        : String
 *  organizationPostalCode  : String
 *  phoneNumber             : PhoneNumber
 *  sendEmails              : Boolean
 *  status                  : Status
 * }
 * Status = 'confirmed' | 'pending' | 'rejected'
 * ToastParameters : {
 *  duration    : Undefined | Null | Integer
 *  message     : Undefined | String
 *  show        : Undefined | Null | Boolean
 *  type        : Undefined | 'error' | 'success'
 * }
 * @param properties Properties
 * @return React.ReactNode
 */
export default function FormTeamUitjeContainer({ selectedDay, event, reload, onClose }) {

	const { backupMode } = useBackupContext();
    const [teamUitjeFormData, setTeamUitjeFormData] = useState({ amountOfParticipants: 12, lunch: false, sendEmails: false });
    const [teamUitjeErrors, setTeamUitjeErrors] = useState({});
	const [loading, setLoading] = useState(false);

    useEffect(() => {
		setTeamUitjeFormData(createTeamuitjeFormData(event));
    }, [event])

	const updateTeamuitje = async (teamUitjeFormData) => {
		setLoading(true);
		try {
			updateTeamuitjeAction(teamUitjeFormData, event);	
			setLoading(false);
			onClose({ message: "Teamuitje is aangepast" });
			reload();
		} catch (err) {
			onClose({ message: 'Fout bij opslaan van wijzigingen', type: 'error' });
			setLoading(false);
			console.log(err);
		}
	}

	/**
	 * AppwriteId = Sized (Equals 36) String
	 * Daypart = 'afternoon' | 'morning'
	 * EmailAddress = Sized (Max 254) String
	 * Iso8601DateTime = String
	 * PhoneNumber = Sized (Max 31) String
	 * Request = {
	 *  $createdAt  : Iso8601DateTime
	 *  $id         : AppwriteId
	 *  date        : Iso8601DateTime
	 *  daypart     : Daypart
	 *  status      : Status
	 * }
	 * RequestDetails = {
	 *  $createdAt              : Iso8601DateTime
	 *  $id                     : AppwriteId
	 *  amountOfParticipants    : Sized (InclusiveRange 12 25) Integer
	 *  comments                : String
	 *  department              : String
	 *  emailAddress            : EmailAddress
	 *  firstName               : String
	 *  lastName                : String
	 *  lunch                   : Boolean
	 *  organizationAddress     : String
	 *  organizationCity        : String
	 *  organizationName        : String
	 *  organizationPostalCode  : String
	 *  phoneNumber             : PhoneNumber
	 *  sendEmails              : Boolean
	 *  status                  : Status
	 * }
	 * Sized S T = T
	 * Status = 'confirmed' | 'pending' | 'rejected'
	 * TeamuitjeFormData = Request & RequestDetails
	 * @param teamUitjeFormData TeamuitjeFormData
	 * @return Promise Void
	 */
	const addTeamuitje = async (teamUitjeFormData) => {
		setLoading(true);
		try {
			addTeamuitjeAction(teamUitjeFormData, selectedDay);
            setLoading(false);
			onClose({ message: "Teamuitje is toegevoegd" });
			reload();
		} catch (err) {
			onClose({ message: 'Fout bij opslaan van wijzigingen', type: 'error' });
			setLoading(false);
			console.log(err);
		}
	}

	const validate = (teamUitjeFormData) => {
		let { errors } = validateTeamuitjeForm(teamUitjeFormData);
		setTeamUitjeErrors(errors);
		return Object.values(errors).some((error) => error);
	}

	const saveTeamuitje = async () => {

		if(backupMode) {
			console.log("restore backup");
			return;
		}

		if(!validate(teamUitjeFormData)) {

			if(event?.data?.mainRequest?.$id) {
				return updateTeamuitje(teamUitjeFormData);
			} else {
				return addTeamuitje(teamUitjeFormData);
			}
		}
	}


    return <FormTeamUitjePanel 
		backupMode={backupMode}
		immutable={event?.data?.mainRequest?.$id != null}
		loading={loading}
		errors={teamUitjeErrors} 
		selectedDay={selectedDay} 
		unavailableDayParts={event?.data?.unavailableDayParts || []} 
		onSave={saveTeamuitje} 
		teamUitje={teamUitjeFormData} setTeamUitje={setTeamUitjeFormData} />


}
