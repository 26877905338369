import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from 'date-fns/locale/nl';
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";

/**
 * Properties -> React.ReactNode
 * Properties = {
 *  classes     : Undefined | String
 *  date        : Date
 *  disabled    : Undefined | Boolean
 *  setDate     : Date -> *
 * }
 * @param properties Properties
 * @return React.ReactNode
 */
export default function ReactDatepicker (properties) {

    const { classes }           = properties;
    const { date }              = properties;
    const { disabled = false }  = properties;
    const { setDate }           = properties;

    registerLocale('nl', nl)


    return (
        <DatePicker
            disabled={disabled}
            placeholderText="Geboortedatum *"
            locale="nl"
            selected={date}
            onChange={(date) => setDate(date)}
            className={classes}
            dateFormat={"dd-MM-yyyy"}
            required
        />
    );
}
