import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useState } from 'react';

/**
 * Properties -> React.ReactNode
 * Properties = {
 *  defaultValue    : Undefined | Integer
 *  disabled        : Undefined | Boolean
 *  hasError        : Boolean
 *  maxAllowed      : Undefined | Number
 *  minAllowed      : Undefined | Number
 *  onChange        : Undefined | (Integer -> *)
 *  onKeyDown       : Undefined | (Event -> *)
 *  value           : Undefined | Integer
 * }
 * @param properties Properties
 * @return React.ReactNode
 */
export default function NumberInput(properties) {

    const { defaultValue = 0 }              = properties;
    const { disabled = false }              = properties;
    const { hasError }                      = properties;
    const { maxAllowed = Infinity }         = properties;
    const { minAllowed = -Infinity }        = properties;
    const { onChange = Function.prototype } = properties;
    const { value }                         = properties;

    const [internalValue, setInternalValue] = useState(defaultValue);

    let extraClassName = '';
    if(hasError) {
        extraClassName += ' border-red-500';
    }

    const changeAmount = (newValue) => {
        if(isNaN(newValue)) {
            return;
        }
        newValue = Number(newValue);
        if(newValue >= minAllowed && newValue <= maxAllowed) {
            onChange(newValue);
        }
    }

    return <div className={`flex flex-row gap-2 rounded-xl border border-gray-300 w-min px-3 items-center ${extraClassName}`}>
        <button
            children={<MinusIcon className="w-5" />}
            className="border border-transparent text-black font-bold p-1 rounded-full"
            disabled={disabled}
            onClick={() => changeAmount(value - 1)}
        />
        <input { ... {

            className:  'h-11 text-center text-base w-10',
            disabled:   disabled,
            inputMode:  'numeric',
            pattern:    '[0-9]*',
            type:       'text',
            value:      value ?? internalValue,

            /**
             * @param event Event
             * @return Void
             */
            onChange: event => {

                const { target } = event;

                let { value: newValue } = target;

                newValue = Number(newValue);
                newValue = Math.max(minAllowed, newValue);
                newValue = Math.min(maxAllowed, newValue);

                if(value == null) {
                    setInternalValue(newValue);
                }

                changeAmount(newValue);

            },

            /**
             * @param event Event
             * @return Void
             */
            onKeyDown: event => {

                const { onKeyDown = Function.prototype }    = properties;
                const { target }                            = event;

                onKeyDown(event);

                ((() => {

                    if(event.altKey || event.ctrlKey || event.shiftKey)
                        return;

                    if(event.key === 'ArrowDown') {

                        event.preventDefault();

                        let x

                        x = Reflect.getPrototypeOf(target)
                        x = Reflect.getOwnPropertyDescriptor(x, 'value');
                        x.set.call(target, Number(target.value) - 1);

                        target.dispatchEvent(new Event('input', {
                            bubbles: true
                        }));

                        return;

                    }

                    if(event.key === 'ArrowUp') {

                        event.preventDefault();

                        let x

                        x = Reflect.getPrototypeOf(target)
                        x = Reflect.getOwnPropertyDescriptor(x, 'value');
                        x.set.call(target, Number(target.value) + 1);

                        target.dispatchEvent(new Event('input', {
                            bubbles: true
                        }));

                        return;

                    }

                    if(/^\D$/.test(event.key)) {
                        event.preventDefault();
                    }

                })());

            }

        } } />
        <button
            children={<PlusIcon className="w-5" />}
            className="border border-transparent text-black font-bold p-1 rounded-full"
            disabled={disabled}
            onClick={() => changeAmount(value + 1)}
        />
    </div>
}
