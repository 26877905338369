import { DateTime } from 'luxon';

import { createCrmTeamuitje }   from '../../../api/backend.js'
import api from "api/api";
import { Server } from "Utils/config";
import { getData } from "Utils/form";
import { cleanProperties } from "Utils/object";

const teamUitjeFormFields = [
	'date',
	'daypart', 
	'amountOfParticipants', 
	'lunch',
	'firstName', 
	'lastName', 
	'emailAddress', 
	'phoneNumber', 
	'organizationName',
	'department',
	'organizationAddress',
	'organizationPostalCode',
	'organizationCity',
	'comments',
    "status",
	'sendEmails'
]

export async function updateTeamuitjeAction(teamUitjeFormData, event) {
	let mainRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, [], ['daypart', 'date', 'status']) };
	delete mainRequestToSave.$$type;
	await api.updateDocument(Server.databaseID, Server.collections['TeamuitjeRequest'], event.data.mainRequest.$id, mainRequestToSave );
	let detailsRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, ['daypart', 'date', 'status']) };
	if(event.data.detailsRequest.$id) {
		console.log("detailsRequestToSave", detailsRequestToSave);
		await api.updateDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], event.data.detailsRequest.$id, detailsRequestToSave);
	} else {
		console.log("detailsRequestToSave (creating)", detailsRequestToSave);
		detailsRequestToSave.requestId = mainRequestToSave.$id;
		await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], detailsRequestToSave);
	}
	return true;
}

/**
 * AppwriteId = Sized (Equals 36) String
 * Daypart = 'afternoon' | 'morning'
 * EmailAddress = Sized (Max 254) String
 * Iso8601Date = String
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  daypart     : Daypart
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt              : Iso8601DateTime
 *  $id                     : AppwriteId
 *  amountOfParticipants    : Sized (InclusiveRange 12 25) Integer
 *  comments                : String
 *  department              : String
 *  emailAddress            : EmailAddress
 *  firstName               : String
 *  lastName                : String
 *  lunch                   : Boolean
 *  organizationAddress     : String
 *  organizationCity        : String
 *  organizationName        : String
 *  organizationPostalCode  : String
 *  phoneNumber             : PhoneNumber
 *  sendEmails              : Boolean
 *  status                  : Status
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * TeamuitjeFormData = Request & RequestDetails
 * @param teamUitjeFormData TeamuitjeFormData
 * @param selectedDay Null | Date | Iso8601Date | Iso8601DateTime
 * @return Promise True
 */
export async function addTeamuitjeAction(teamUitjeFormData, selectedDay) {
	let mainRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, [], ['daypart', 'date', 'status']) };
	mainRequestToSave.date = selectedDay;
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	let request = await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequest'], mainRequestToSave);
	let requestId = request.$id;
	let detailsRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, ['daypart', 'date', 'status']) };
	let createData = { ...detailsRequestToSave, requestId: requestId };
	const requestDetails = await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], createData);

	await createCrmTeamuitje({
		amountOfParticipants:       requestDetails.amountOfParticipants,
		appwriteRequestDetailsId:   requestDetails.$id,
		appwriteRequestId:          requestId,
		creationTime:               request.$createdAt,
		daypart:                    requestDetails.daypart,
		department:                 requestDetails.department,
		emailAddress:               requestDetails.emailAddress,
		eventDate:                  DateTime.fromISO(request.date).toISODate(),
		firstName:                  requestDetails.firstName,
		lastName:                   requestDetails.lastName,
		lunch:                      requestDetails.lunch,
		organizationAddress:        requestDetails.organizationAddress,
		organizationCity:           requestDetails.organizationCity,
		organizationName:           requestDetails.organizationName,
		organizationPostalCode:     requestDetails.organizationPostalCode,
		phoneNumber:                requestDetails.phoneNumber,
		requesterComments:          requestDetails.requesterComments,
		sendEmails:                 requestDetails.sendEmails
	});

	return true;
}

export async function restoreTeamuitjeBackup(event, details) {
	let mainEvent = cleanProperties(event);
	mainEvent.$id = event.$id;
	let eventDetails = cleanProperties(details);
	eventDetails.$id = details.$id;
	let request = await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequest'], mainEvent);
	eventDetails.requestId = request.$id;
	await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], eventDetails);
	return 2;
}

/**
 * AppwriteId = Sized (Equals 36) String
 * Daypart = 'afternoon' | 'morning'
 * EmailAddress = Sized (Max 254) String
 * Event = {
 *  type : 'teamuitje'
 *  data : {
 *      detailsRequest      : RequestDetails
 *      mainRequest         : Request
 *      unavailableDayParts : Array Daypart
 *  }
 * }
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  daypart     : Daypart
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt              : Iso8601DateTime
 *  $id                     : AppwriteId
 *  amountOfParticipants    : Sized (InclusiveRange 12 25) Integer
 *  comments                : String
 *  department              : String
 *  emailAddress            : EmailAddress
 *  firstName               : String
 *  lastName                : String
 *  lunch                   : Boolean
 *  organizationAddress     : String
 *  organizationCity        : String
 *  organizationName        : String
 *  organizationPostalCode  : String
 *  phoneNumber             : PhoneNumber
 *  sendEmails              : Boolean
 *  status                  : Status
 * }
 * Result = TeamuitjeFormData | {
 *  amountOfParticipants    : 12
 *  lunch                   : False
 *  sendEmails              : False
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * TeamuitjeFormData = Request & RequestDetails
 * @param event Event
 * @return Result
 */
export function createTeamuitjeFormData(event) {
	if(event && event.data && event.data.mainRequest) {
		let teamUitjeFormData = { ...getData(event.data.mainRequest, teamUitjeFormFields), ...getData(event.data.detailsRequest, teamUitjeFormFields) };
		return teamUitjeFormData;
	}
	return { amountOfParticipants: 12, lunch: false, sendEmails: false };

}

/**
 * AppwriteId = Sized (Equals 36) String
 * Daypart = 'afternoon' | 'morning'
 * EmailAddress = Sized (Max 254) String
 * Iso8601DateTime = String
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  daypart     : Daypart
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt              : Iso8601DateTime
 *  $id                     : AppwriteId
 *  amountOfParticipants    : Sized (InclusiveRange 12 25) Integer
 *  comments                : String
 *  department              : String
 *  emailAddress            : EmailAddress
 *  firstName               : String
 *  lastName                : String
 *  lunch                   : Boolean
 *  organizationAddress     : String
 *  organizationCity        : String
 *  organizationName        : String
 *  organizationPostalCode  : String
 *  phoneNumber             : PhoneNumber
 *  sendEmails              : Boolean
 *  status                  : Status
 * }
 * Result = {
 *
 *  foundErrors : Boolean
 *
 *  errors : {
 *      daypart                 : Boolean
 *      emailAddress            : Boolean
 *      firstName               : Boolean
 *      lastName                : Boolean
 *      organizationAddress     : Boolean
 *      organizationCity        : Boolean
 *      organizationName        : Boolean
 *      organizationPostalCode  : Boolean
 *      phoneNumber             : Boolean
 *  }
 *
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * TeamuitjeFormData = Request & RequestDetails
 * @param teamUitjeFormData TeamuitjeFormData
 * @return Result
 */
export function validateTeamuitjeForm(teamUitjeFormData) {
	let errors = {};
	errors['daypart'] = !teamUitjeFormData.daypart;
	errors['firstName'] = !teamUitjeFormData.firstName;
	errors['lastName'] = !teamUitjeFormData.lastName;
	errors['emailAddress'] = !(teamUitjeFormData.emailAddress && teamUitjeFormData.emailAddress.match(/[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/));
	errors['phoneNumber'] = !teamUitjeFormData.phoneNumber;
	errors['organizationName'] = !teamUitjeFormData.organizationName;
	errors['organizationAddress'] = !teamUitjeFormData.organizationAddress;
	errors['organizationPostalCode'] = !teamUitjeFormData.organizationPostalCode;
	errors['organizationCity'] = !teamUitjeFormData.organizationCity;
	let errorsFound = Object.keys(errors).length > 0;
	return { errors, errorsFound };
}
