import { useState, useEffect } from "react";

import FormAanpakkerVoorEenDagPanel from "../FormAanpakkerVoorEenDagPanel";
import { updateAanpakkerVoorEenDagAction, addAanpakkerVoorEenDagAction, validateAanpakkerVoorEenDagForm, createAanpakkerVoorEenDagFormData } from "../Actions/Aanpakker";

/**
 * Properties -> React.ReactNode
 * AppwriteId = Sized (Equals 36) String
 * Event = {
 *  type : 'aanpakker'
 *  data : {
 *      detailsRequest  : RequestDetails
 *      mainRequest     : Request
 *  }
 * }
 * Iso8601Date = String
 * Iso8601DateTime = String
 * Properties = {
 *  event       : Event
 *  onClose     : (Undefined | ToastParameters) -> *
 *  reload      : (Undefined | ToastParameters) -> *
 *  selectedDay : Null | Iso8601Date
 * }
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt              : Iso8601DateTime
 *  $id                     : AppwriteId
 *  comments                : String
 *  organizationAddress     : Null | String
 *  organizationCity        : Null | String
 *  organizationName        : Null | String
 *  organizationPostalCode  : Null | String
 *  sendEmails              : Boolean
 * }
 * Status = 'confirmed' | 'pending' | 'rejected'
 * ToastParameters : {
 *  duration    : Undefined | Null | Integer
 *  message     : Undefined | String
 *  show        : Undefined | Null | Boolean
 *  type        : Undefined | 'error' | 'success'
 * }
 * @param properties Properties
 * @return React.ReactNode
 */
export default function FormAanpakkerVoorEenDagContainer({ selectedDay, event, reload, onClose }) {

    const [aanpakkerVoorEenDagFormData, setAanpakkerVoorEenDagFormData] = useState({ sendEmails: false, participants: [{}] });
    const [aanpakkerVoorEenDagErrors, setAanpakkerVoorEenDagErrors] = useState([]);
	const [loading, setLoading] = useState(false);

    useEffect(() => {
		let aanpakkerVoorEenDagFormData = createAanpakkerVoorEenDagFormData(event);
		if(aanpakkerVoorEenDagFormData) {
			setAanpakkerVoorEenDagFormData(aanpakkerVoorEenDagFormData);
		}
    }, [event])

	const updateAanpakkerVoorEenDag = async (aanpakkerVoorEenDagFormData) => {
		setLoading(true);
		try {
			await updateAanpakkerVoorEenDagAction(aanpakkerVoorEenDagFormData, event);
			setLoading(false);
			onClose({ message: 'Aanpakker voor een dag is aangepast' });
			reload();
		
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}

	/**
	 * AanpakkerVoorEenDagFormData -> Promise Void
	 * AanpakkerVoorEenDagFormData = Request & RequestDetails & {
	 *  participants : Array ({} | Participant)
	 * }
	 * AppwriteId = Sized (Equals 36) String
	 * EmailAddress = Sized (Max 254) String
	 * Iso8601DateTime = String
	 * Participant = {
	 *  birthdate       : Iso8601DateTime
	 *  emailAddress    : EmailAddress
	 *  firstName       : String
	 *  lastName        : String
	 *  phoneNumber     : PhoneNumber
	 * }
	 * PhoneNumber = Sized (Max 31) String
	 * Request = {
	 *  $createdAt  : Iso8601DateTime
	 *  $id         : AppwriteId
	 *  date        : Iso8601DateTime
	 *  status      : Status
	 * }
	 * RequestDetails = {
	 *  $createdAt              : Iso8601DateTime
	 *  $id                     : AppwriteId
	 *  comments                : String
	 *  organizationAddress     : Null | String
	 *  organizationCity        : Null | String
	 *  organizationName        : Null | String
	 *  organizationPostalCode  : Null | String
	 *  sendEmails              : Boolean
	 * }
	 * Sized S T = T
	 * Status = 'confirmed' | 'pending' | 'rejected'
	 * @param aanpakkerVoorEenDag AanpakkerVoorEenDagFormData
	 * @return Promise Void
	 */
    const addAanpakkerVoorEenDag = async (aanpakkerVoorEenDag) => {
		console.log("aanpakker voor een dag form submit");
		setLoading(true);
		try {
			await addAanpakkerVoorEenDagAction(aanpakkerVoorEenDag, selectedDay, aanpakkerVoorEenDag);
			reload();
			setLoading(false);
			onClose({ message: 'Aanpakker voor een dag is aangemaakt' });
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	

	const validate = (aanpakkerVoorEenDagFormData) => {
		const { errors, foundErrors } = validateAanpakkerVoorEenDagForm(aanpakkerVoorEenDagFormData);
		setAanpakkerVoorEenDagErrors(errors);
		return foundErrors;
	}

	const saveAanpakkerVoorEenDag = async () => {

		if(!validate(aanpakkerVoorEenDagFormData)) {

			if(event?.data?.mainRequest?.$id) {
				return updateAanpakkerVoorEenDag(aanpakkerVoorEenDagFormData);
			} else {
				return addAanpakkerVoorEenDag(aanpakkerVoorEenDagFormData);
			}
		}
	}


    return <FormAanpakkerVoorEenDagPanel 
        errors={aanpakkerVoorEenDagErrors} 
        immutable={event?.data?.mainRequest?.$id != null}
        selectedDay={selectedDay} 
		loading={loading}
        onSave={saveAanpakkerVoorEenDag} 
        aanpakkerVoorEenDag={aanpakkerVoorEenDagFormData} 
        setAanpakkerVoorEenDag={setAanpakkerVoorEenDagFormData} />


}
