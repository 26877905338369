import { DateTime } from 'luxon';

import { createCrmAanpakkerVoorEenDagParticipant }  from '../../../api/backend.js';
import api from "api/api";
import { Server } from "Utils/config";
import { getData } from "Utils/form";
import { cleanProperties } from "Utils/object";


const aanpakkerVoorEenDagFormFields = [
    'date',
    'status',
    'comments',
	'organizationName',
	'organizationAddress',
	'organizationPostalCode',
	'organizationCity',
	'sendEmails'
];

const participantFields = [
	'firstName', 
	'lastName', 
	'emailAddress', 
	'phoneNumber',
	'birthdate'
]

export async function updateAanpakkerVoorEenDagAction(aanpakkerVoorEenDagFormData, event) {
	let mainRequestToSave = { ...getData(aanpakkerVoorEenDagFormData, aanpakkerVoorEenDagFormFields, [], ['date', 'status']) };
	delete mainRequestToSave.$$type;
	await api.updateDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequest'], event.data.mainRequest.$id, mainRequestToSave );
	let detailsRequestToSave = { ...getData(aanpakkerVoorEenDagFormData, aanpakkerVoorEenDagFormFields, ['date', 'status']) };
	console.log("detailsRequestToSave", detailsRequestToSave);
	if(event.data.detailsRequest.$id) {
		console.log("detailsRequestToSave", detailsRequestToSave);
		await api.updateDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestDetails'], event.data.detailsRequest.$id, detailsRequestToSave);
	} else {
		console.log("detailsRequestToSave (creating)", detailsRequestToSave);
		detailsRequestToSave.requestId = mainRequestToSave.$id;
		await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestDetails'], detailsRequestToSave);
	}
	for(let participant of aanpakkerVoorEenDagFormData.participants) {
		if(participant.$id && !participant.$$deleted) {
			await api.updateDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestParticipant'], participant.$id, getData(participant, participantFields));
		} else if(participant.$id && participant.$$deleted) {
			await api.deleteDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestParticipant'], participant.$id);
		} else if(!participant.$id && !participant.$$deleted) {
			let participantToSave = getData(participant, participantFields);
			participantToSave.requestId = event.data.mainRequest.$id;
			await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestParticipant'], participantToSave);
		}	
	}
	return true;
}

/**
 * AanpakkerVoorEenDagFormData = Request & RequestDetails & {
 *  participants : Array ({} | Participant)
 * }
 * AppwriteId = Sized (Equals 36) String
 * EmailAddress = Sized (Max 254) String
 * Iso8601Date = String
 * Iso8601DateTime = String
 * Participant = {
 *  birthdate       : Date
 *  emailAddress    : EmailAddress
 *  firstName       : String
 *  lastName        : String
 *  phoneNumber     : PhoneNumber
 * }
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt              : Iso8601DateTime
 *  $id                     : AppwriteId
 *  comments                : String
 *  organizationAddress     : Null | String
 *  organizationCity        : Null | String
 *  organizationName        : Null | String
 *  organizationPostalCode  : Null | String
 *  sendEmails              : Boolean
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * @param aanpakkerVoorEenDagFormData AanpakkerVoorEenDagFormData
 * @param selectedDay Null | Date | Iso8601Date | Iso8601DateTime
 * @param aanpakkerVoorEenDag AanpakkerVoorEenDagFormData
 * @return Promise True
 */
export async function addAanpakkerVoorEenDagAction(aanpakkerVoorEenDagFormData, selectedDay, aanpakkerVoorEenDag) {
	let mainRequestToSave = { ...getData(aanpakkerVoorEenDagFormData, aanpakkerVoorEenDagFormFields, [], ['date', 'status']) };
	mainRequestToSave.date = selectedDay;
	mainRequestToSave.amountOfParticipants = aanpakkerVoorEenDagFormData.participants.length;
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	let request = await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequest'], mainRequestToSave);
	let requestId = request.$id;
	let createData = { ...getData(aanpakkerVoorEenDagFormData, aanpakkerVoorEenDagFormFields, ['date', 'status']), requestId: requestId };

	const requestDetails = await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestDetails'], createData);

	const participants = await Promise.all(aanpakkerVoorEenDag.participants.map(async participant => {
		return await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestParticipant'], {
			...participant,
			requestId: requestId
		});
	}));

	const eventDate = DateTime.fromISO(request.date).toISODate();

	await Promise.all(participants.map(async participant => {
		await createCrmAanpakkerVoorEenDagParticipant({
			appwriteParticipantId:      participant.$id,
			appwriteRequestDetailsId:   requestDetails.$id,
			appwriteRequestId:          requestId,
			birthdate:                  DateTime.fromISO(participant.birthdate).toISODate(),
			creationTime:               request.$createdAt,
			department:                 requestDetails.department,
			emailAddress:               participant.emailAddress,
			eventDate:                  eventDate,
			firstName:                  participant.firstName,
			lastName:                   participant.lastName,
			organizationAddress:        requestDetails.organizationAddress,
			organizationCity:           requestDetails.organizationCity,
			organizationName:           requestDetails.organizationName,
			organizationPostalCode:     requestDetails.organizationPostalCode,
			phoneNumber:                participant.phoneNumber,
			requesterComments:          requestDetails.requesterComments,
			sendEmails:                 requestDetails.sendEmails
		});
	}));

	return true;
}

/**
 * for all amountOfParticipants : Sized (Min 0) Integer
 * AanpakkerVoorEenDagFormData = Request & RequestDetails & {
 *  participants : Sized 1 (Array {}) | Sized amountOfParticipants Participant
 * }
 * AppwriteId = Sized (Equals 36) String
 * EmailAddress = Sized (Max 254) String
 * Iso8601DateTime = String
 * Participant = {
 *  birthdate       : Date
 *  emailAddress    : EmailAddress
 *  firstName       : String
 *  lastName        : String
 *  phoneNumber     : PhoneNumber
 * }
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  $createdAt  : Iso8601DateTime
 *  $id         : AppwriteId
 *  date        : Iso8601DateTime
 *  status      : Status
 * }
 * RequestDetails = {
 *  $createdAt              : Iso8601DateTime
 *  $id                     : AppwriteId
 *  comments                : String
 *  organizationAddress     : Null | String
 *  organizationCity        : Null | String
 *  organizationName        : Null | String
 *  organizationPostalCode  : Null | String
 *  sendEmails              : Boolean
 * }
 * Result = {
 *
 *  foundErrors : Boolean
 *
 *  errors : Sized amountOfParticipants (Array {
 *      birthdate       : Boolean
 *      emailAddress    : Boolean
 *      firstName       : Boolean
 *      lastName        : Boolean
 *      phoneNumber     : Boolean
 *  })
 *
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * @param aanpakkerVoorEenDagFormData AanpakkerVoorEenDagFormData
 * @return Result
 */
export function validateAanpakkerVoorEenDagForm(aanpakkerVoorEenDagFormData) {
	let errors = [];
	let foundErrors = false;

	aanpakkerVoorEenDagFormData.participants.forEach((participant, index) => {
		let participantErrors = {};
		participantErrors['firstName'] = !participant.firstName;
		participantErrors['lastName'] = !participant.lastName;
		participantErrors['emailAddress'] = !(participant.emailAddress && participant.emailAddress.match(/[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/));
		participantErrors['phoneNumber'] = !participant.phoneNumber;
		participantErrors['birthdate'] = !participant.birthdate;
		foundErrors = foundErrors || Object.values(participantErrors).some((error) => error);
		errors[index] = participantErrors;
	});

	return { errors, foundErrors}
}

/**
 * AanpakkerVoorEenDagFormData = Request & RequestDetails & {
 *  participants : Array ({} | Participant)
 * }
 * AppwriteId = Sized (Equals 36) String
 * EmailAddress = Sized (Max 254) String
 * Event = {
 *  type : 'aanpakker'
 *  data : {
 *      detailsRequest  : RequestDetails
 *      mainRequest     : Request
 *  }
 * }
 * Iso8601DateTime = String
 * Participant = {
 *  $id             : AppwriteId
 *  birthdate       : Date
 *  emailAddress    : EmailAddress
 *  firstName       : String
 *  lastName        : String
 *  phoneNumber     : PhoneNumber
 * }
 * PhoneNumber = Sized (Max 31) String
 * Request = {
 *  date    : Iso8601DateTime
 *  status  : Status
 * }
 * RequestDetails = {
 *  comments                : String
 *  organizationAddress     : Null | String
 *  organizationCity        : Null | String
 *  organizationName        : Null | String
 *  organizationPostalCode  : Null | String
 *  sendEmails              : Boolean
 * }
 * Result = AanpakkerVoorEenDagFormData | {
 *  participants    : Unit {}
 *  sendEmails      : False
 * }
 * Sized S T = T
 * Status = 'confirmed' | 'pending' | 'rejected'
 * @param event Event
 * @return Result
 */
export function createAanpakkerVoorEenDagFormData(event) {
	if(event && event.data && event.data.mainRequest) {
		let aanpakkerVoorEenDagFormData = { 
			...getData(event.data.mainRequest, aanpakkerVoorEenDagFormFields), 
			...getData(event.data.detailsRequest, aanpakkerVoorEenDagFormFields),
			participants: Object.values(event.data.participants || {}).map((participant) => ({ 
				...getData(participant, participantFields), 
				$id: participant.$id,
				birthdate: participant.birthdate ? new Date(participant.birthdate) : null 
			}))
		};
		return aanpakkerVoorEenDagFormData;
	} else {
		return { sendEmails: false, participants: [{}] };
	}
}

export async function restoreAanpakkerBackup(event, details, participants) {
	let mainEvent = cleanProperties(event);
	mainEvent.$id = event.$id;
	let eventDetails = cleanProperties(details);
	let eventParticipants = participants.map((participant) => {
		let filteredParticipant = cleanProperties(participant)
		filteredParticipant.$id = participant.$id;
		return filteredParticipant;
	});
	eventDetails.$id = details.$id;
	mainEvent.amountOfParticipants = eventParticipants.length;
	let mainRequest = await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequest'], mainEvent);
	eventDetails.requestId = mainRequest.$id;
	await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestDetails'], eventDetails);
	for(let participant of eventParticipants) {
		participant.requestId = mainRequest.$id;
		await api.createDocument(Server.databaseID, Server.collections['AanpakkerVoorEenDagRequestParticipant'], participant);
	}
	return 2 + (participants?.length || 0);
}

